import { AxiosRequestConfig } from 'axios'

import { EbalanceBackendService } from './EbalanceBackendService'

import {
  AddFavoritesFood,
  AddFavoritesMotionLevel,
  AddFavoritesRecipe,
  AddFavoritesUnion,
  BackendError,
  BackendErrorData,
  BodyCriterion,
  BodyMeasurementPoint,
  BodyMeasurementPointPatch,
  CoreBackendFood,
  CoreBackendFoodPatchData,
  CoreBackendFoodPostData,
  CoreBackendRecipe,
  CoreBackendRecipePatchData,
  CoreBackendRecipePostData,
  CoreBackendUser,
  CoreBackendUserInfo,
  DateRange,
  EatableSource,
  EatableSourcePost,
  EnergyDistributionType,
  EnergyHistoryItem,
  FavoritesCategories,
  FavoritesFood,
  FavoritesMapping,
  FavoritesRecipe,
  FetchMenuPlanPayload,
  FoodCategory,
  JournalEatableRecord,
  JournalEnergyMotionRecord,
  JournalEnergyRecord,
  JournalMotionRecord,
  MealCategory,
  MenuPlanWeek,
  Motion,
  NewProgram,
  NutritionSummary,
  Nutritions,
  Program,
  QuantifiedEatable,
  Query,
  UserSettings,
  UserSettingsDynamic
} from 'Models'
import { JournalHydrationRecord } from 'Models/journalHydrationRecords'
import { Formatter, Utils } from 'Utils'
import { getDefaultErrorMessage } from 'Utils/helpers/errorMessages'

interface CoreBackendErrorData extends BackendErrorData {
  bodyCriterion?: BodyCriterion
}

export class CoreBackendService extends EbalanceBackendService {
  constructor(config: AxiosRequestConfig, public userId?: string) {
    super(config, userId)
    this.client.interceptors.response.use(undefined, errorInterceptor)
  }

  // User
  async fetchUsers(): Promise<CoreBackendUser[]> {
    const { data } = await this.client.get(`users`)
    return data
  }

  async fetchUser(): Promise<CoreBackendUser> {
    const { data } = await this.client.get(`users/${this.userId}`)
    return data
  }

  async updateUser(user: Omit<CoreBackendUser, 'id'>): Promise<CoreBackendUser> {
    return await this.client.patch(`users/${this.userId}`, user)
  }

  async resetUser(): Promise<void> {
    await this.client.delete(`users/${this.userId}`, {
      validateStatus: (status) => {
        return status === 204 || status === 404
      }
    })

    await this.client.post('users', {
      id: this.userId
    })
  }

  async fetchUserInfo(): Promise<CoreBackendUserInfo> {
    const response = await this.client.get(`users/${this.userId}`)

    if (response.data.needToCreateUser) {
      await this.client.post('users', {
        id: this.userId
      })
    }

    const { data } = await this.client.get(`users/${this.userId}/info`)
    return Utils.convertObjectDatestringToDate(data, ['registrationDate'])
  }

  async fetchUserSettings(): Promise<UserSettings> {
    const { data } = await this.client.get(`users/${this.userId}/settings`)
    return data
  }

  async updateUserSettings(settings: UserSettingsDynamic): Promise<void> {
    return this.client.patch(`users/${this.userId}/settings`, settings)
  }

  async fetchUserGoalNutritions(query: { date: Date }): Promise<Nutritions> {
    const rangeString = this.buildUrlQuery(query)
    const { data } = await this.client.get(`users/${this.userId}/goal-nutritions?${rangeString}`)

    return data
  }

  async fetchUserEnergyRequirements(query: { date: Date }): Promise<void> {
    const rangeString = this.buildUrlQuery(query)
    const { data } = await this.client.get(`users/${this.userId}/energy-requirements?${rangeString}`)
    return data
  }

  async fetchMenuplan(query: FetchMenuPlanPayload): Promise<MenuPlanWeek> {
    const queryString = this.buildUrlQuery(query)

    const { data } = await this.client.get(`users/${this.userId}/menu-plan?${queryString}`)
    return data
  }

  async updateMenuPlanSlots(id: string, hidden = false): Promise<void> {
    return this.client.patch(`users/${this.userId}/journal/menu-plan/slots/${id}`, { hidden })
  }

  // My Analytics
  async fetchNutritionSummary(range: DateRange): Promise<NutritionSummary> {
    const rangeString = this.buildUrlQuery(range)
    const { data } = await this.client.get(`users/${this.userId}/journal/analytics/nutritions-summary?${rangeString}`)
    return data
  }

  async fetchEnergyHistory(range: DateRange): Promise<EnergyHistoryItem[]> {
    const rangeString = this.buildUrlQuery(range)
    const { data } = await this.client.get(`users/${this.userId}/journal/analytics/energy-history?${rangeString}`)

    return Utils.convertObjectDatestringToDate(data, ['date'])
  }

  async fetchEnergyMealDistribution(range: DateRange): Promise<EnergyDistributionType> {
    const rangeString = this.buildUrlQuery(range)
    const { data } = await this.client.get(
      `users/${this.userId}/journal/analytics/energy-meal-distribution?${rangeString}`
    )
    return data
  }

  // Journal / Eatables Records
  async fetchJournalEatableRecords(query: { date: Date }): Promise<JournalEatableRecord[]> {
    const queryString = this.buildUrlQuery(query)
    const { data } = await this.client.get(`users/${this.userId}/journal/eatable-records?${queryString}`)

    return Utils.convertObjectDatestringToDate(data)
  }

  async addJournalEatableRecord(
    quantifiedEatable: QuantifiedEatable,
    mealCategory: MealCategory,
    date: Date,
    source: string | undefined
  ): Promise<JournalEatableRecord> {
    const payload = {
      mealCategory,
      foodId: Utils.eatableFoodId(quantifiedEatable),
      recipeId: Utils.eatableRecipeId(quantifiedEatable),
      quantity: quantifiedEatable.quantity,
      unit: quantifiedEatable.unit,
      datetime: date,
      source
    }
    const { data } = await this.client.post(`users/${this.userId}/journal/eatable-records`, payload)
    return Utils.convertObjectDatestringToDate(data)
  }

  async updateJournalEatableRecord({
    id,
    unit,
    quantity,
    mealCategory,
    datetime
  }: JournalEatableRecord): Promise<JournalEatableRecord> {
    return this.client.patch(`users/${this.userId}/journal/eatable-records/${id}`, {
      unit,
      quantity,
      mealCategory,
      datetime
    })
  }

  async deleteJournalEatableRecord(recordId: string): Promise<JournalEatableRecord> {
    return this.client.delete(`users/${this.userId}/journal/eatable-records/${recordId}`)
  }

  // Journal / Energy Records

  async fetchJournalEnergyRecords(query: { date?: Date } = {}): Promise<JournalEnergyRecord[]> {
    const queryString = this.buildUrlQuery(query)
    const { data } = await this.client.get(`users/${this.userId}/journal/energy-records?${queryString}`)
    return Utils.convertObjectDatestringToDate(data)
  }

  async addJournalEnergyRecord(
    description: string,
    energy: number,
    mealCategory: MealCategory,
    date: Date
  ): Promise<JournalEnergyRecord> {
    const payload = {
      mealCategory,
      energy,
      description,
      datetime: date
    }
    const { data } = await this.client.post(`users/${this.userId}/journal/energy-records`, payload)
    return Utils.convertObjectDatestringToDate(data)
  }

  async updateJournalEnergyRecord(record: JournalEnergyRecord): Promise<JournalEnergyRecord> {
    const { id, ...payload } = record
    return this.client.patch(`users/${this.userId}/journal/energy-records/${id}`, payload)
  }

  async deleteJournalEnergyRecord(recordId: string): Promise<JournalEnergyRecord> {
    return this.client.delete(`users/${this.userId}/journal/energy-records/${recordId}`)
  }

  // Journal / Motion Records

  async fetchJournalMotionRecords(query: { date?: Date } = {}): Promise<JournalMotionRecord[]> {
    const queryString = this.buildUrlQuery(query)
    const { data } = await this.client.get(`users/${this.userId}/journal/motion-records?${queryString}`)
    return Utils.convertObjectDatestringToDate(data)
  }

  async addJournalMotionRecord(duration: number, motionLevelId: string, date: Date): Promise<JournalMotionRecord> {
    const payload = {
      duration,
      motionLevelId,
      datetime: date
    }
    const { data } = await this.client.post(`users/${this.userId}/journal/motion-records`, payload)
    return Utils.convertObjectDatestringToDate(data)
  }

  async updateJournalMotionRecord(id: string, duration: number, motionLevelId: string, datetime?: Date): Promise<void> {
    return this.client.patch(`users/${this.userId}/journal/motion-records/${id}`, {
      duration,
      motionLevelId,
      datetime
    })
  }

  async deleteJournalMotionRecord(recordId: string): Promise<void> {
    return this.client.delete(`users/${this.userId}/journal/motion-records/${recordId}`)
  }

  // Journal / Energy Motion Records

  async fetchJournalEnergyMotionRecords(query: { date?: Date } = {}): Promise<JournalEnergyMotionRecord[]> {
    const queryString = this.buildUrlQuery(query)
    const { data } = await this.client.get(`users/${this.userId}/journal/energy-motion-records?${queryString}`)
    return Utils.convertObjectDatestringToDate(data)
  }

  async addJournalEnergyMotionRecord(
    description: string,
    energy: number,
    duration: number,
    date: Date
  ): Promise<JournalEnergyMotionRecord> {
    const payload = {
      energy,
      duration,
      description,
      datetime: date
    }

    const { data } = await this.client.post(`users/${this.userId}/journal/energy-motion-records`, payload)
    return Utils.convertObjectDatestringToDate(data)
  }

  async updateJournalEnergyMotionRecord(record: JournalEnergyMotionRecord): Promise<JournalEnergyMotionRecord> {
    const { id, ...payload } = record
    return this.client.patch(`users/${this.userId}/journal/energy-motion-records/${id}`, payload)
  }

  async deleteJournalEnergyMotionRecord(recordId: string): Promise<JournalEnergyMotionRecord> {
    return this.client.delete(`users/${this.userId}/journal/energy-motion-records/${recordId}`)
  }

  // Journal / Hydration Records

  fetchHydrationRecords(query: { date: Date }): Promise<{ data: JournalHydrationRecord[] }> {
    const queryString = this.buildUrlQuery(query)
    return this.client.get(`users/${this.userId}/journal/hydration-records?${queryString}`)
  }

  fetchHydrationGoal(query: { date: Date }): Promise<{ data: { amount: number } }> {
    const queryString = this.buildUrlQuery(query)
    return this.client.get(`users/${this.userId}/journal/hydration/goal?${queryString}`)
  }

  addHydrationrecord(payload: {
    datetime: Date
    amount: number
    source: string
  }): Promise<{ data: JournalHydrationRecord }> {
    return this.client.post(`users/${this.userId}/journal/hydration-records`, payload)
  }

  deleteHydrationrecord(id: string): Promise<{ data: '' }> {
    return this.client.delete(`/users/${this.userId}/journal/hydration-records/${id}`)
  }

  // Foods

  async fetchFoods(query: Query = { ownerId: this.userId }): Promise<CoreBackendFood[]> {
    const queryString = this.buildUrlQuery({ ...query, hidden: false })
    const { data } = await this.client.get(`/foods?${queryString}`)
    return data
  }

  async fetchFood(id: string): Promise<CoreBackendFood> {
    const { data } = await this.client.get(`/foods/${id}`)
    return data
  }

  async addFood(food: CoreBackendFoodPostData): Promise<CoreBackendFood> {
    const { data } = await this.client.post(`/foods`, { ...food, ownerId: this.userId })
    return data
  }

  async updateFood({ id, ...food }: CoreBackendFoodPatchData): Promise<void> {
    await this.client.patch(`/foods/${id}`, food)
  }

  async updateFoodVisibility(id: string, hidden: boolean): Promise<void> {
    await this.client.patch(`/foods/${id}`, { hidden })
  }

  // Recipes

  async fetchRecipes(query: Query = {}): Promise<CoreBackendRecipe[]> {
    const queryString = this.buildUrlQuery({ ...query, hidden: false, ownerId: this.userId })
    const { data } = await this.client.get(`/recipes/?${queryString}`)
    return data
  }

  async fetchRecipe(id: string): Promise<CoreBackendRecipe> {
    const { data } = await this.client.get(`/recipes/${id}`)
    return data
  }

  async addRecipe(recipeData: CoreBackendRecipePostData): Promise<CoreBackendRecipe> {
    const { data } = await this.client.post(`/recipes`, { ...recipeData, ownerId: this.userId })
    return data
  }

  async updateRecipe({ id, ...recipeData }: CoreBackendRecipePatchData): Promise<void> {
    await this.client.patch(`/recipes/${id}`, recipeData)
  }

  async updateRecipeVisibility(id: string, hidden: boolean): Promise<void> {
    await this.client.patch(`/recipes/${id}`, { hidden })
  }

  // motion
  async fetchMotion(id: string): Promise<Motion> {
    const { data } = await this.client.get(`/motions/${id}`)
    return data
  }

  // Favorites
  async fetchAllFavorites(): Promise<FavoritesCategories> {
    const { data: foods } = await this.client.get(`/users/${this.userId}/favorites/foods`)
    const { data: recipes } = await this.client.get(`/users/${this.userId}/favorites/recipes`)
    const { data: motionLevels } = await this.client.get(`/users/${this.userId}/favorites/motion-levels`)
    return {
      [FavoritesMapping.FOODS]: foods,
      [FavoritesMapping.RECIPES]: recipes,
      [FavoritesMapping.MOTIONS]: motionLevels
    }
  }

  async addFavorite(
    payload: AddFavoritesFood | AddFavoritesRecipe | AddFavoritesMotionLevel,
    favoritesMapping: FavoritesMapping
  ): Promise<FavoritesFood | FavoritesRecipe> {
    const { data } = await this.client.post(`/users/${this.userId}/favorites/${favoritesMapping}`, payload)
    return data
  }

  async removeFavorite(favoritesId: string, favoritesMapping: FavoritesMapping): Promise<void> {
    await this.client.delete(`/users/${this.userId}/favorites/${favoritesMapping}/${favoritesId}`)
  }

  async updateFavorite(id: string, favoritesMapping: FavoritesMapping, payload: AddFavoritesUnion): Promise<void> {
    await this.client.put(
      `/users/${this.userId}/favorites/${favoritesMapping}/${id}`,
      'quantity' in payload
        ? {
            quantity: payload.quantity,
            unit: payload.unit
          }
        : { duration: payload.duration }
    )
  }

  // BodyMeasurements
  async fetchBodyMeasurements({ bodyCriterion }: { bodyCriterion?: BodyCriterion }): Promise<BodyMeasurementPoint[]> {
    const queryString = this.buildUrlQuery({ bodyCriterion })
    const { data } = await this.client.get(`/users/${this.userId}/body-measurements?${queryString}`)

    return Utils.convertObjectDatestringToDate(data)
  }

  async postBodyMeasurements(payload: BodyMeasurementPoint): Promise<BodyMeasurementPoint> {
    const { data } = await this.client.post(
      `/users/${this.userId}/body-measurements`,
      Utils.convertObjectDatetimeToString(payload)
    )

    return Utils.convertObjectDatestringToDate(data)
  }

  async updateBodyMeasurements(payload: BodyMeasurementPointPatch): Promise<BodyMeasurementPoint> {
    return await this.client.patch(`/users/${this.userId}/body-measurements/${payload.id}`, payload)
  }

  async fetchLatestBodyMeasurement({ type }: { type?: BodyCriterion }): Promise<BodyMeasurementPoint> {
    const { data } = await this.client.get(`/users/${this.userId}/body-measurements/${type}/latest`)
    return Utils.convertObjectDatestringToDate(data)
  }

  // Program
  async fetchPrograms(): Promise<Program[]> {
    const { data } = await this.client.get(`/users/${this.userId}/programs`)

    return Utils.convertObjectDatestringToDate(data, ['startDate', 'goalDate', 'terminationDate'])
  }

  async fetchCurrentProgram(): Promise<Program[]> {
    const { data } = await this.client.get(`/users/${this.userId}/programs/latest`)
    return Utils.convertObjectDatestringToDate(data, ['startDate', 'goalDate', 'terminationDate'])
  }

  async postProgram(program: NewProgram): Promise<Program> {
    const { data } = await this.client.post(`/users/${this.userId}/programs`, program)

    return Utils.convertObjectDatestringToDate(data, ['startDate', 'goalDate', 'terminationDate'])
  }

  // Food Categories
  async fetchFoodCategories(): Promise<FoodCategory[]> {
    const { data } = await this.client.get(`/food-categories`)
    return data
  }

  // Eatable Sources
  async fetchEatableSources(): Promise<EatableSource[]> {
    const { data: userSources } = await this.client.get(`/users/${this.userId}/eatable-sources`)
    const { data: systemSources } = await this.client.get('/users/eBalance/eatable-sources')

    return [...userSources, ...systemSources]
  }

  async addEatableSources({ name, url, imageUrl }: EatableSourcePost): Promise<EatableSource> {
    const { data } = await this.client.post(`/eatable-sources`, { name, url, imageUrl, ownerId: this.userId })
    return data
  }
}

// Error Handling

const buildUserErrorMessage = (error: CoreBackendErrorData): string | undefined => {
  const formattedRange = Formatter.formatRange(error.range)
  const formattedBodyCriterion = error.bodyCriterion ? Formatter.formatBodyCriterion(error.bodyCriterion) : 'n/a'

  if (error.code === 'RANGE_CONSTRAINT_VIOLATION' && error.bodyCriterion) {
    return `Ihr ${formattedBodyCriterion} von ${error.givenValue} liegt nicht zwischen ${formattedRange.min} und ${formattedRange.max}.`
  }

  if (error.code === 'BODY_MEASUREMENT_UNREALISTIC_CHANGE') {
    return (
      `Ihre Änderung des ${formattedBodyCriterion} ist zu gross. ` +
      `Ihr ${formattedBodyCriterion} sollte zwischen ${formattedRange.min} und ${formattedRange.max} liegen.`
    )
  }

  // default to common error cases
  return EbalanceBackendService.buildUserErrorMessage(error)
}

const errorInterceptor = (error: BackendError): Promise<unknown> => {
  if (error.config.url?.includes('users') && error.config.method === 'get' && error.response?.status === 404) {
    return Promise.resolve({ data: { needToCreateUser: true } })
  }
  if (error.request)
    if (Array.isArray(error.response?.data)) {
      const backendErrors = error.response?.data as CoreBackendErrorData[]
      error.userErrorMessages = backendErrors.map(
        (errorItem) => buildUserErrorMessage(errorItem) || getDefaultErrorMessage(error.response?.status)
      )
    }

  return Promise.reject(error)
}
