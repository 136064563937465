import { uniqueId } from 'lodash'
import React, { FC, Fragment, ReactNode, useState } from 'react'

import FoodModal from '../Modals/FoodModal/FoodModal'
import RecipeModal from '../Modals/RecipeModal/RecipeModal'

import { InfoPlaceholder } from 'Components'
import { DebugPanel } from 'Components/Debug'
import {
  CategoryGroupedJournalEatableContainerMapping,
  CategoryJournalEatableContainerMapping,
  JournalEatableContainerObject,
  JournalEnergyMotionRecord,
  JournalEnergyRecord,
  JournalMotionRecord,
  MealCategory,
  QuantifiedEatable,
  QuantifiedFood,
  QuantifiedRecipe
} from 'Models'
import JournalCardEatableContainer from 'Pages/JournalPage/components/JournalCardEatableContainer/JournalCardEatableContainer'
import JournalCardEnergyContainer from 'Pages/JournalPage/components/JournalCardEnergyContainer'
import JournalCardEnergyMotionContainer from 'Pages/JournalPage/components/JournalCardEnergyMotionContainer'
import JournalCardHydrationContainer from 'Pages/JournalPage/components/JournalCardHydrationContainer/JournalCardHydrationContainer'
import JournalCardMotionContainer from 'Pages/JournalPage/components/JournalCardMotionContainer'
import { getFlattenedMenuPlanContainers } from 'Pages/MenuPlanPage/journalEatableCardContainersForDate'
import { Calculator, Formatter } from 'Utils'

type Props = {
  journalEatableRecordContainers: CategoryJournalEatableContainerMapping
  menuPlanContainers: CategoryGroupedJournalEatableContainerMapping
  flattenedJournalEatableRecordsContainers: JournalEatableContainerObject[]
  journalEnergyMotionRecords: JournalEnergyMotionRecord[]
  journalEnergyRecords: JournalEnergyRecord[]
  journalMotionRecords: JournalMotionRecord[]
  weight: number
  menuPlanHidden: boolean
  acceptedEnergyMotionSum?: number
}

const JournalDay: FC<Props> = ({
  journalEatableRecordContainers,
  menuPlanContainers,
  flattenedJournalEatableRecordsContainers,
  journalEnergyMotionRecords,
  journalEnergyRecords,
  journalMotionRecords,
  weight,
  menuPlanHidden,
  acceptedEnergyMotionSum
}: Props) => {
  const [detailRecipe, setDetailRecipe] = useState<QuantifiedRecipe>()
  const [detailFood, setDetailFood] = useState<QuantifiedFood>()

  // Recipe Detail Modal >>>>

  const openRecipeDetails = (quantifiedEatable: QuantifiedEatable): void => {
    const { recipe, quantity, unit } = quantifiedEatable
    setDetailRecipe({ recipe, quantity, unit })
  }

  const closeRecipeDetails = (): void => {
    setDetailRecipe(undefined)
  }

  const renderRecipeModal = (): JSX.Element => {
    return <RecipeModal quantifiedRecipe={detailRecipe as QuantifiedRecipe} onClose={closeRecipeDetails} />
  }

  // <<< Recipe Detail Modal

  // Food Detail Modal >>>>

  const openFoodDetails = (quantifiedEatable: QuantifiedEatable): void => {
    const { food, quantity, unit } = quantifiedEatable
    setDetailFood({ food, quantity, unit })
  }

  const closeFoodDetails = (): void => {
    setDetailFood(undefined)
  }

  const renderFoodModal = (): JSX.Element => {
    return <FoodModal quantifiedFood={detailFood as QuantifiedFood} onClose={closeFoodDetails} />
  }

  // <<< Food Detail Modal

  const renderEatableContainer = (
    {
      eatableCardObjects,
      eatableCardsActiveIndex,
      journalRecordId,
      menuPlanSlotId,
      hint,
      source
    }: JournalEatableContainerObject,
    mealCategory: MealCategory
  ): ReactNode => {
    if (!eatableCardObjects || eatableCardObjects.length === 0) {
      return (
        <DebugPanel>
          empty eatable: <code>{JSON.stringify(eatableCardObjects)}</code>
        </DebugPanel>
      )
    }

    return (
      <JournalCardEatableContainer
        eatableCardObjects={eatableCardObjects}
        eatableCardsActiveIndex={eatableCardsActiveIndex}
        mealCategory={mealCategory}
        journalRecordId={journalRecordId}
        menuPlanSlotId={menuPlanSlotId}
        hint={hint}
        source={source}
        onCardClick={(eatable) =>
          eatable.recipe ? openRecipeDetails(eatable) : eatable.food && openFoodDetails(eatable)
        }
      />
    )
  }

  const renderJournalEatableCards = (
    eatableCardObjects: JournalEatableContainerObject[],
    category: MealCategory
  ): ReactNode =>
    eatableCardObjects.map((cardObject) => {
      if (menuPlanHidden && !cardObject.journalRecordId) {
        return null
      }
      return (
        <Fragment key={cardObject.journalRecordId || cardObject.menuPlanSlotId}>
          {renderEatableContainer(cardObject, category)}
        </Fragment>
      )
    })

  const renderJournalEnergyCards = (records: JournalEnergyRecord[]): ReactNode =>
    records.map((record: JournalEnergyRecord) => (
      <JournalCardEnergyContainer key={record.id} journalEnergyRecord={record} />
    ))

  const flattenedMenuPlanContainers = getFlattenedMenuPlanContainers({ menuPlanContainers: menuPlanContainers })

  const renderCategoryCards = (category: MealCategory): JSX.Element | null => {
    const energyRecordsForCategory = journalEnergyRecords.filter((record) => record.mealCategory === category)

    const eatableCardsForCategory = flattenedJournalEatableRecordsContainers.filter(
      (card) => card.mealCategory === category && !(menuPlanHidden && !card.journalRecordId)
    )

    const menuPlanCardsForCategory = flattenedMenuPlanContainers.filter(
      (card) => card.mealCategory === category && !(menuPlanHidden && !card.journalRecordId)
    )
    const isEmpty =
      eatableCardsForCategory.length === 0 &&
      energyRecordsForCategory.length === 0 &&
      menuPlanCardsForCategory.length === 0

    const menuPlanJournalRecordIds = menuPlanContainers[category]
      .map((group) => group.filter((slot) => slot.journalRecordId).map((slot) => slot.journalRecordId))
      .flat()

    const journalEatableCardContainersWithoutMenuPlanEntries = journalEatableRecordContainers[category].filter(
      (journalRecord) => !menuPlanJournalRecordIds.includes(journalRecord.journalRecordId)
    )

    const sumEnergyCards = Calculator.sumEnergy(energyRecordsForCategory)
    const sumEatableCards = Calculator.sumEnergyFromEatableContainerObjects(
      eatableCardsForCategory.filter((card) => card.journalRecordId)
    )

    const renderDivider = (index: number): ReactNode => {
      if (menuPlanHidden) {
        return null
      }
      if (
        journalEatableRecordContainers[category].length === 0 &&
        energyRecordsForCategory.length === 0 &&
        index === menuPlanContainers[category].length - 1
      ) {
        return null
      }
      return <hr className="mb-2 menuPlanDivider" />
    }

    return !isEmpty ? (
      <>
        <div className="standardcard-sectiontitle mt-3 mb-1">
          <span>{Formatter.formatMealCategory(category)}</span>
          <span>{Formatter.formatEnergy(sumEnergyCards + sumEatableCards)}</span>
        </div>
        <div className="row">
          <div className="col-12">
            {menuPlanContainers[category].map((cards, index) => {
              return (
                <Fragment key={`slot:${cards[0].menuPlanSlotId}`}>
                  {renderJournalEatableCards(cards, category)}
                  {renderDivider(index)}
                </Fragment>
              )
            })}
            {renderJournalEatableCards(journalEatableCardContainersWithoutMenuPlanEntries, category)}
            {renderJournalEnergyCards(energyRecordsForCategory)}
          </div>
        </div>
      </>
    ) : null
  }

  const renderMotionCards = (): ReactNode =>
    journalMotionRecords.length > 0 || journalEnergyMotionRecords.length > 0 ? (
      <>
        <div className="standardcard-sectiontitle mt-3 mb-1">
          <span>Aktivitäten</span>
          <span>{Formatter.formatEnergy(acceptedEnergyMotionSum, true)}</span>
        </div>
        <div className="row">
          <div className="col-12">
            {journalMotionRecords.map((record) => (
              <JournalCardMotionContainer key={record.id} journalMotionRecord={record} weight={weight} />
            ))}
            {journalEnergyMotionRecords.map((record: JournalEnergyMotionRecord) => (
              <JournalCardEnergyMotionContainer key={record.id} journalEnergyMotionRecord={record} />
            ))}
          </div>
        </div>
      </>
    ) : null

  const renderJournalCards = (): JSX.Element => {
    const breakfastCards = renderCategoryCards('BREAKFAST')
    const lunchCards = renderCategoryCards('LUNCH')
    const dinnerCards = renderCategoryCards('DINNER')
    const snackCards = renderCategoryCards('SNACK')
    const motionCards = renderMotionCards()

    if (
      breakfastCards === null &&
      lunchCards === null &&
      dinnerCards === null &&
      snackCards === null &&
      motionCards === null
    ) {
      return (
        <InfoPlaceholder text="Es gibt noch keinen Eintrag im Tagebuch und keinen Menüplan, fügen Sie jetzt über die Suche ein Nahrungsmittel, ein Rezept oder eine Aktivität hinzu. Dazu klicken Sie in das Suchfeld über dem Dashboard und suchen die einzelnen Nahrungsmittel oder Rezepte und fügen diese über “Hinzufügen” und “Speichern” zu Ihrem Tagebuch hinzu." />
      )
    }

    return (
      <>
        {breakfastCards}
        {lunchCards}
        {dinnerCards}
        {snackCards}
        {motionCards}
      </>
    )
  }

  return (
    <>
      <JournalCardHydrationContainer key={uniqueId()} />
      {renderJournalCards()}
      {renderRecipeModal()}
      {renderFoodModal()}
    </>
  )
}

export default JournalDay
